<template>
  <div>
    <div class="authortitle">重新投稿</div>

    <div style="padding: 20px">
      <div class="form-title">文章信息:</div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
        style="width: 60%"
      >
        <el-form-item label="稿件">
          <el-upload
            class="upload-demo"
            ref="upload"
            :action="path"
            :file-list="fileList"
            :data="form"
            :on-change="changfile"
            :on-success="uploadsuccsee"
            :before-upload="beforeUpload"
            :auto-upload="false"
            accept=".doc,.docx"
          >
            <div class="downloadfj" slot="trigger">
              <i class="el-icon-paperclip"></i>点击上传附件
            </div>
            <div slot="tip" class="el-upload__tip">只能上传单个word文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="论题" prop="title">
          <el-input v-model="form.title" placeholder="请输入论题"></el-input>
        </el-form-item>
        <el-form-item label="摘要">
          <el-input
            v-model="form.summary"
            placeholder="请输入摘要"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="关键词">
          <el-input
            v-model="form.keyword"
            placeholder="请输入关键词"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.remarks"
            placeholder="请输入备注"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-button type="danger" @click="submitUpload('form')"
            >重新投稿</el-button
          >
        </el-form-item>
        
      </el-form>
    </div>
  </div>
</template>
<script>
import VDistpicker from 'v-distpicker'
import { upload } from '../../../API/ZZD/storage'
export default {
  components: {

    VDistpicker
  },
  data () {
    //验证手机号的规则;
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法的手机号"));
    };
    return {
      form: {
        title: '',
        summary: '',
        keyword: '',
        remarks: '',

        works_id: "",


      },
      qkname: "",
      checked: false,
      fileList: [],
      "province": "",
      "city": "",
      "path": "",
      jybtn: true,
      file: "",
      fileLists: '', //接收文流
      status: '',
      select: false,
      rules: {

        title: [
          { required: true, message: '请输入论题', trigger: 'blur' }
        ]
      },

    }

  },
  mounted () {
    console.log(this.$route.query.works_id)
    this.path = this.URL + "/author/UploadAgain"
    this.form.works_id = this.$route.query.works_id
    this.summary = ''
    this.keyword = ''
    this.remarks = ''
  },
  watch: {

    '$route' (to, from) { //监听路由是否变化
      if (to.path == "/") { //跳转到哪个页面
        location.reload()
        sessionStorage.clear()
      }
    },
  },


  methods: {
    submitUpload (formName) {//文件上传
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.fileList);
          if (this.fileList == "") {
            this.$message.error("未上传稿件")
          } else {
            this.$refs.upload.submit();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    uploadsuccsee (res) {//文件上传成功后，清除session
      console.log(res)
      if (res.code == 0) {
        this.$message.success("重新上传成功")
        this.$router.push('/Pending/todoorder')
        setTimeout(function () {
          sessionStorage.clear()
        }, 2000)
      } else {
        this.$message.error("重新上传失败")
        setTimeout(function () {
        }, 2000)
      }


    },
    beforeUpload (file) {
      this.file.name = ''
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'doc'
      const extension2 = testmsg === 'docx'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 doc、docx格式!',
          type: 'warning'
        });
      }
      return extension || extension2
    },
    changfile (file, fileList) {//文件上传选择文件后触发的
      let that = this
      that.fileList = fileList
      that.file = file

      // 读取文件的摘要

      var param = new FormData(); //创建form对象

      param.append('file', that.file.raw)

      that.$axios({
        method: 'post',
        url: '/PoiWord/upload',
        data: param,
        headers: {
          'Content-Type': 'multipart/form-data',

        },
      }).then(function (res) {

        that.form.title = res.data.data.bt //读取标题
        that.form.summary = res.data.data.zy // 读取摘要
        that.form.keyword = res.data.data.gjc // 读取关键词
        if (that.file.raw == '') {
          that.form.summary = ''
          that.form.summary = '' // 读取摘要
          that.form.keyword = '' // 读取关键词
        }

      })

    },


    showregin (e) {
      this.form.region = e.province.value + e.city.value
    }
  },
}

</script>

<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.form-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.downloadfj {
  width: 220px;
  height: 40px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
}
</style>